<template>
  <div class="home-content" style="">
    <v-row>

      <v-col cols="12" sm="11" md="10" class="mx-auto" style="z-index:1!important">
        <h1 class="mt-5 mb-0 font-flower main-title d-none d-md-inline-block" style="">{{ $store.state.app.siteName }}</h1>
        <h1 class="mt-5 mb-0 font-flower main-title d-inline-block d-md-none" style="">Nanoug'</h1>
        <h1 class="mb-0 font-flower main-title d-inline-block d-md-none" style="margin-top:-35px;">librarie</h1>
       
       
        <p class="font-flower main-home-subtitle px-5" >
          Des solutions concrètes
          <span class="d-inline-block mx-1"> qui simplifient le travail</span>
          <span class="d-inline-block mx-1"> des enseignants de maternelle !</span>
        </p>
      </v-col>

      
      <v-col cols="10" sm="10" class="mx-auto px-0 px-md-4">
        
        <v-row v-if="$store.state.app.data.config.length > 0">
          <v-col cols="12" lg="8" class="px-0 px-md-5">
            <v-alert v-if="configHome2 != null && configHome2.text != ''" color="red darken-3" 
                     outlined style="font-weight: 600 !important;" class="d-inline-block" 
                     v-html="nl2br(configHome2[0].text)"/>
            <p style="font-weight: 600 !important;" 
               v-if="configHome1 != null && configHome1.text != ''"
               v-html="nl2br(configHome1[0].text)">
            </p>
            <!-- <v-btn outlined @click="showMore=!showMore">
              <v-icon small>mdi-chevron-down</v-icon> en savoir plus
            </v-btn> -->
          </v-col>
        </v-row>

        <br>

        <v-row v-show="true">
          <template v-for="(category) in categories">
            <v-col cols="12" sm="6" md="4" lg="3" 
                   class="pa-5 mt-5 text-center" 
                   :key="category.name">
              <span class="font-dancing" style="font-size:40px;">{{ category.name }}</span>
              <router-link :to="'/category/'+category._id">
                <v-img class="mx-auto mt-3" :src="baseUrl() + category.image"></v-img>
              </router-link>
              <!-- <br><small>{{ category.description.substr(0, 50) }}...</small> -->
            </v-col>
          </template>
        </v-row>
        
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" sm="12" class="pa-0 my-5">
        <v-card elevation="0" color="transparent" class="section">
          <v-col cols="12" sm="12" class="mx-auto text-center font-flower">
            <!-- <v-btn x-large style="font-size:1.3em;" rounded color="black" dark class="ma-3" to="/gallery">
            Explorer la galerie <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>
            </v-btn> -->
          </v-col>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>


<script>
    import core from '../plugins/core.js'

    import '@/assets/css/home.css';
    
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "App",
        components: {
          
        },
        data: function () {
            return {
                categories: [],
                categoryToShow: false,
                showcategoryDialog: false,
                showMore: false,
                configHome1: null,
                configHome2: null,
            };
        },
        mounted: function(){
          this.initData()
          this.$root.$on('closeDialogcategory', () => { this.closeDialogcategory() })
          this.$store.dispatch('app/incStat', '/home')
        },
        methods: {
            
            async initData(){
              if(this.categories.length == 0)
              await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "category", 
                                query: {  }, 
                                sort: { 'position' : 1 } 
                              })
              this.categories = this.$store.state.app.data.category


              let config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_TXT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome1 = config.entities[0]

              config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_ALERT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome2 = config.entities[0]

              console.log("categories", this.categories)
            },
            openDialogcategory(category){
              this.categoryToShow = category
              this.showcategoryDialog = true
            },
            closeDialogcategory(){
              this.categoryToShow = null
              this.showcategoryDialog = false
            },
            nl2br: function(str, is_xhtml){
              return core.nl2br(str, is_xhtml)
            },
            baseUrl(){ return core.baseUrl() }
        },
        computed:{
          classShowMore(){ return this.showMore ? '' : 'd-none d-md-flex' }
        }
    };
</script>

